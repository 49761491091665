.hero-container {
  width: 100vw;
  height: 100vh;
  background: rgb(254, 254, 246);
  background: linear-gradient(
    0deg,
    rgba(254, 254, 246, 1) 0%,
    rgba(252, 247, 241, 1) 49%,
    rgba(247, 247, 241, 1) 81%,
    rgba(229, 246, 243, 1) 100%
  );
}

.hero-container a {
  text-decoration: none;
}

.hero-background {
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right;
  display: flex;
  flex-direction: column;
}

.logo {
  width: 200px;
  height: 35px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 0.5rem;
}

.hero-content {
  height: 100%;
  width: 60vw;
  padding-left: 10vw;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: 6vmin;
  color: #5a4c7b;
  flex-direction: column;
  overflow-wrap: normal;
}

@media (max-width: 600px) {
  .hero-background {
    background-position-x: -700px;
  }

  .hero-content {
    width: auto;
    justify-content: center;
    align-items: center;
    padding: unset;
  }
}
