.project-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
}

.project-container a {
  user-select: none;
  text-decoration: none;
}

.jungo-project {
  display: flex;
  min-height: 100vh;
  width: 100vw;
  background-color: #aececf;
  color: #557d7e;
  background-size: 50vw;
  background-position-x: right;
  background-position-y: center;
  background-repeat: no-repeat;
  align-items: center;
}

.jungo-details {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50vw;
}

.jungo-details > img {
  height: 10vw;
}

.taai-project,
.aiforum-project,
.blockgo-project {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.taai-project > img,
.aiforum-project > img,
.blockgo-project > img {
  max-height: 85vmin;
  max-width: 90vw;
}

.taai-project {
  background-color: #ecf3f3;
}

.aiforum-project {
  background-color: #b8cdb8;
}

.blockgo-project {
  background-color: #a2c095;
  color: white;
}
