.goto-button {
  border: 2px solid #5a4c7b;
  border-radius: 100px;
  padding: 0.5rem 1rem;
  display: flex;
  font-weight: 400;
  font-size: 20px;
  background: none;
  outline: none;
  user-select: none;
  color: #5a4c7b;
  margin-bottom: 2rem;
}

.goto-button > svg {
  height: 15px;
  display: flex;
  align-self: center;
}
