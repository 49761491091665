.about-container {
  display: flex;
  width: 100vw;
  height: auto;
  min-height: 100vh;
  background-image: linear-gradient(
    180deg,
    #365883 0%,
    #51788d 37%,
    #7fac9e 99%
  );
  justify-content: center;
  flex-direction: column;
}

.introduce-container {
  display: flex;
  height: 100vh;
  color: white;
  padding: 0 10vw;
  text-align: justify;
  justify-content: center;
  flex-direction: column;
  font-weight: 400;
  font-size: 18px;
}

.awards-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-bottom: 10vh;
}

.awards-container h1 {
  font-family: Times, "Times New Roman", serif;
  font-weight: bold;
  color: white;
  text-align: center;
}

.medal-card {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: pre;
  text-align: center;
  margin: 2rem;
}

.medal-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
