footer {
  height: 50px;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

footer > a {
  margin-right: 1rem;
  transition: ease-in-out 0.5s;
  color: darkgray;
}

footer > #email:hover {
  color: #557d7e;
}

footer > #facebook:hover {
  color: #3b5998;
}

footer > #twitter:hover {
  color: #0084b4;
}
